.search-container {
	$max-search-width: 770px;
	$search-height: 80px;
	$input-right-padding: 40px;

	position: relative;
	max-width: $max-search-width;
	width: 100%;
	height: $search-height;
	color: $primary-text-color;
	line-height: 27px;
	border-top-left-radius: $border-radius-default;
	border-top-right-radius: $border-radius-default;
	box-sizing: border-box;
	margin: 0 auto;
	padding: 0 20px 0 70px;
	background: {
		color: $primary-background-color-light;
		image: url('../images/search-icon.svg');
		repeat: no-repeat;
		size: $icon-size;
		position: left 24px center;
	};

	@include mobile {
		padding-left: 40px;
		background-position: left 10px center;
	}

	.search-container__places {
		position: relative;
		width: 100%;
		height: 100%;

		.search-container__places-input {
			height: 30px;
			font-size: 15px;
			border-bottom: 1px solid $primary-text-color-transparent;
			padding-right: $input-right-padding;
			width: calc(100% - #{$input-right-padding});
		}

		.search-container__places-label {
			color: $primary-text-color-transparent;
			font-size: 15px;
			padding-right: $input-right-padding;
			width: calc(100% - #{$input-right-padding});
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:after {
				background-color: $primary-text-color;
			}
		}
	}

	.gps-icon-container {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		cursor: pointer;
	}
}
