.register-container {
	padding: 100px 0;
	background-color: $background-color-light;

	@include mobile {
		padding: 20px;
	}

	.button {
		width: 100%;
	}
}

.register-form {
	$form-width: 720px;
	max-width: $form-width;
	margin: 0 auto;

	.form__label.terms {
		font-size: 13px;

		a {
			color: $blue-text-color;
		}
	}
}
