.requests-table-wrapper {
	.requests-table-wrapper__table-row {
		cursor: pointer;
	}
}

.request-form {
	.request-form__message-field {
		font-size: 15px;
		color: $text-color-brandind-dark;
		line-height: 24px;
	}
}

.request-images {
	$request-image-height: 350px;
	width: 100%;

	.request-images__image {
		height: $request-image-height;
		width: 100%;
		margin: 5px 0;
		background: {
			repeat: no-repeat;
			position: center;
			size: cover;
		};

		&:last-child {
			margin-bottom: 20px;
		}
	}
}

.connections-container {
	padding: 0;

	.row-connections {
		width: 224px;
		padding: 12px 20px;

		.row-connections__connections-field {
			height: 30px;
			display: block;
			line-height: 30px;
		}
	}

	.recipient {
		margin: 10px 0;
	}
}
