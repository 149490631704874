.login-form {
	$login-form-width: 570px;

	padding: 75px 0;
	width: $login-form-width;
	margin: 0 auto;
	text-align: center;

	@include mobile {
		width: auto;
		padding: 50px 15px;
	}

	.login-form__input {
		font-size: 14px;
		color: $secondary-text-color;
		line-height: 17px;
		padding-bottom: 7px;
	}

	.login-form__label {
		font-size: 15px;
	}

	.login__button {
		background: $primary-background-color;
		color: $primary-text-color;
	}

	.login__description {
		margin: 10px 0;
		text-align: center;
	}

	.login-form__sign-in-btn {
		margin: 10px 0 55px;
	}

	.login__forgot-password-container {
		text-align: right;

		.login__forgot-password--input,
		.login__cancel--input {
			text-transform: none;
		}
	}

	// .login-form__field {
	//   &.login-form__field--mail-icon {
	//     background-image: url('../images/mail-icon.svg');
	//   }
	//
	//   &.login-form__field--lock-icon {
	//     background-image: url('../images/lock-icon.svg');
	//   }
	// }

	.login-form__password-field {
		position: relative;

		.show-password-btn {
			position: absolute;
			right: 17px;
			top: 50%;
			margin-top: -($icon-size / 2);
			height: $icon-size;
			width: $icon-size;
			cursor: pointer;
			background: {
				image: url('../images/eye-icon.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: center;
			}
		}
	}
}

.forgot__message {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: $text-color-branding;
}
