.review {
	$max-review-width: 760px;
	$avatar-size: 50px;
	$avatar-size-mobile: 38px;
	$offset-side: 15px;

	display: flex;
	// padding-left: $avatar-size + 50px;
	text-align: left;
	max-width: $max-review-width;
	margin: 45px auto;

	.review__side-container {
		width: 100px;
		padding-left: $offset-side;

		@include mobile {
			width: 52px;
		}
	}

	.review__main-container {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.review__avatar {
		width: $avatar-size;
		height: $avatar-size;
		border-radius: 100%;
		background: {
			image: url('../images/default-user-avatar.svg');
			repeat: no-repeat;
			size: cover;
			position: center;
		};

		@include mobile {
			width: $avatar-size-mobile;
			height: $avatar-size-mobile;
		}
	}

	.review__header {
		margin-bottom: 30px;
		padding-right: $offset-side;

		.review__header--author-name {
			font-size: 16px;
			color: $text-color-brandind-dark;
			line-height: 19px;
			margin-bottom: 5px;
		}

		.review__header--creation-time {
			font-size: 14px;
			color: $text-color-dark-lighter;
			line-height: 17px;
		}
	}

	.review__content {
		font-weight: 300;
		font-size: 16px;
		color: $text-color-dark;
		line-height: 28px;
		margin-bottom: 25px;
		padding-right: $offset-side;
	}

	.review-actions-container {
		border-top: 1px solid $border-color-default;

		.review-action-container__item {
			display: inline-block;
			margin-right: 60px;
			padding: 10px 0;
			color: $text-color-brandind-dark;
			font-size: 15px;

			&:last-child {
				margin-right: 0;
			}

			@include mobile {
				display: block;
				margin-right: 0;
				border-bottom: 1px solid $border-color-default;

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.review-action-container__button {
			display: flex;
			align-items: center;
			padding-left: $icon-size + 12px;
			line-height: 18px;
			text-transform: none;
			letter-spacing: 0;
			background: {
				repeat: no-repeat;
				size: $icon-size;
				position: left center;
			};

			&.review-action-container__button--thank {
				background-image: url('../images/gift-icon.svg');
			}

			&.review-action-container__button--special-offer {
				background-image: url('../images/tag-icon.svg');
			}

			&.review-action-container__button--delete {
				background-image: url('../images/delete-dark-icon.svg');
			}
		}
	}
}
