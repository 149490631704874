$min-desktop-width: 1025px;
$max-tablet-width: $min-desktop-width - 1px;
$max-mobile-width: 799px;

@mixin mobile {
	@media (max-width: #{$max-mobile-width}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$max-tablet-width}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$min-desktop-width}) {
		@content;
	}
}

@mixin retina {
	@media  only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
		@content;
	}
}

@mixin clearfix() {
	&::after {
		content: '';
		clear: both;
		display: block;
	}
}
