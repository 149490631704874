.table {
	$table-row-min-height: 56px;

	width: 100%;
	border: 0;
	table-layout: fixed;

	.table__cell {
		vertical-align: middle;
		white-space: pre-wrap;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: left;
		border-top: 0;
		border-bottom: 1px solid $border-color-default;

		&.table__cell--active {
			color: $text-color-brandind-dark;
		}

		&.table__cell--left-align {
			text-align: left;
		}

		&.table__cell--center-align {
			text-align: center;
		}

		&.table__cell--delete {
			width: 30px;
			cursor: pointer;

			.svg-color {
				fill: $text-color-dark-lighter;
				opacity: 0.3;
				transition: opacity 0.3s ease;
			}

			// scss-lint:disable NestingDepth
			&:hover {
				.svg-color {
					opacity: 0.83;
				}
			}
		}

		&.table__cell--options {
			cursor: pointer;
			padding-left: 5px;
		}
	}

	.table__cell-header {
		text-align: left;
		line-height: 17px;
		padding-bottom: 16px;
		border-bottom: 0;

		&.table__cell-header--filter {
			cursor: pointer;
			background: {
				repeat: no-repeat;
				size: $icon-size - 3px;
				position: left center;
			};
		}

		&.table__cell-header--descending {
			background-image: url('../images/descending-icon.svg');
		}

		&.table__cell-header--ascending {
			background-image: url('../images/ascending-icon.svg');
		}

		&.table__cell-header--small {
			width: 85px;
		}

		&.table__cell-header--x-small {
			width: 50px;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}

		&.table__cell-header--medium {
			width: 130px;
		}

		&.table__cell-header--x-medium {
			width: 150px;
		}

		&.table__cell-header--xx-medium {
			width: 95px;
		}

		&.table__cell-header--large {
			width: 220px;
		}
	}

	.table__icon-wrapper {
		display: none;
		width: $icon-size;
	}

	.table__row {
		height: $table-row-min-height;
		transition: background-color 0.3s ease;

		.table__row--menu-btn {
			//opacity: 0;
			transition: opacity 0.3s ease;
		}

		&:hover {
			background-color: $background-color-hover;

			.table__icon-wrapper {
				display: flex;
			}

			//.table__row--menu-btn {
			//  opacity: 1;
			//}
		}
	}

	.table__row-checkbox-container {
		width: 51px;
		border-top: 0;
		border-bottom: 1px solid $border-color-default;
	}

	.table__row-checkbox {
		top: 50%;
		margin-top: -12px;

		&.mdl-data-table__select {
			margin-top: -12px;
		}
	}

	.table__tooltip-icon {
		vertical-align: baseline;
		height: 19px;
		margin-left: 2px;

		.svg-main {
			height: 19px;
			width: 19px;
		}

		.svg-color {
			fill: $background-color-light;
			fill-opacity: 1;
		}
	}

	.table__header--option {
		width: 60px;
		padding-left: 5px;
	}

	.table__breadcrump {
		padding-right: 4px + $icon-size;
		padding-left: 4px;
		background-position: right center;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
			background-image: none;
		}

		&:only-child {
			background-image: none;
			padding: 0;
		}
	}
}

.table-prefilters {
	padding: 0 $table-side-offest;
	max-width: $max-table-width;
	min-height: $table-prefilters-height;
	font-size: 15px;

	@media (max-width: 1200px) {
		padding: 0 $table-side-offest-mobile;
	}

	@media (min-width: $max-content-width) {
		margin: 0 auto;
		width: 100%;
	}

	@include tablet {
		padding: 0 15px;
		margin-bottom: 25px;

		.select2.select2-container {
			min-width: 100%;
			text-align: left;
		}
	}

	.table-prefilter {
		margin: 10px 0;
		display: flex;

		&.table-prefilter--primary-text-color {
			color: $primary-text-color;
		}

		.table-prefilter__right {
			display: flex;
			flex: 1;
			justify-content: flex-end;
		}

		.table-prefilter__left {
			display: flex;
			flex: 1;
		}
	}
}

.table-filters {
	display: flex;
	align-items: center;
	height: 65px;
	background: $primary-background-color-light;
	border-top-left-radius: $border-radius-default;
	border-top-right-radius: $border-radius-default;
	font-weight: 600;
	font-size: 13px;
	color: $text-color-light-dark;

	@include tablet {
		display: block;
		height: auto;
		padding: 15px;
	}

	.table-filters__left-side {
		display: flex;
		flex: 1;
		justify-content: flex-start;
		font-size: 18px;
		padding-left: 68px;

		@include tablet {
			display: block;
			padding: 0 0 10px;
			text-align: center;
		}

		&.table-filters__left-side--smaller {
			padding-left: 24px;
		}
	}

	.table-filters__right-side {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		padding-right: 20px;

		@include tablet {
			.js-counter-label {
				width: 40%;
			}

			.js-filter-menu-btn {
				width: 60%;

				.mdl-button {
					width: 100%;
				}
			}
		}

		.js-counter-label {
			padding: 10px;
		}
	}

	.table-filters__selection {
		color: $secondary-text-color;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		padding-right: $icon-size + 4px;
		cursor: pointer;
		background: {
			image: url('../images/arrow-down-black.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: right center;
		};
	}

	.table-filters__menu-container {
		position: relative;
		background: $background-color-light;

		border: 1px solid $background-color-light;
		padding-left: 5px;
		border-radius: 2px;
	}
}


.table-pagination {
	background-color: $background-color-light;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
	font-size: 14px;
	color: $text-color-dark;
	text-align: right;
	padding: 20px 24px;

	.table-pagination__total-pages {
		margin-right: 34px;
	}

	.table-pagination__navigation {
		.table-pagination__navigation--left {
			cursor: pointer;
			margin-right: 24px;
		}

		.table-pagination__navigation--right {
			cursor: pointer;
		}
	}

	.table-pagination__indicator {
		float: left;
	}
}

.table-empty-container {
	background-color: $background-color-light;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
	font-size: 16px;
	color: $text-color-dark;
	text-align: left;
	padding: 40px 24px;
}

.table-wrapper {
	padding: 0 20px;

	&.table-wrapper--clients,
	&.table-wrapper--offers,
	&.table-wrapper--recommendations {
		margin: 0 auto;
		padding: 0 $table-side-offest 85px $table-side-offest;
		max-width: $max-table-width;

		@media (max-width: 1200px) {
			padding: 0 $table-side-offest-mobile 0 $table-side-offest-mobile;
		}
	}

	@include tablet {
		&.table-wrapper--offers,
		&.table-wrapper--recommendations {
			display: none;
		}
	}

	.table {
		@include tablet {
			// display: none;
			width: auto;

			tr td:nth-child(5),
			tr th:nth-child(5),
			tr td:nth-child(6),
			tr th:nth-child(6),
			tr td:nth-child(7),
			tr th:nth-child(7),
			tr td:nth-child(8),
			tr th:nth-child(8),
			tr td:nth-child(4),
			tr th:nth-child(4) {
				display: none;
			}
		}

		.table__header {
			background: $primary-background-color-light;
			font-size: 14px;

			th {
				color: $secondary-text-color-light;
			}
		}
	}
}

.table-wrapper-mobile {
	display: none;
	text-align: center;

	@include tablet {
		display: block;
	}

	.table-row-card {
		margin: 0 15px 20px 15px;
		vertical-align: top;
	}

	.table-pagination {
		background-color: transparent;
	}
}
