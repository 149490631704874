.menu-drawer {
	$menu-left-paading: 70px;
	$link-height: 48px;

	background-color: $primary-background-color-light;
	color: $primary-text-color;

	&.is-visible {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100vh;
	}

	.menu-drawer__title {
		text-align: right;
		padding: 20px;
		line-height: 20px;
	}

	.menu-drawer__close {
		display: inline-block;
		cursor: pointer;

		.svg-icon {
			fill-opacity: 1;
			fill: $primary-text-color;
		}
	}

	.menu__text {
		color: $secondary-text-color-light;
		min-height: $link-height;
		line-height: $link-height;
		padding: 0 0 0 $menu-left-paading;
		font-size: 14px;
	}

	.menu-drawer__links-container {
		border-bottom: 1px solid $border-color-branding-dark;
		padding-bottom: 23px;
	}

	.menu-drawer__coommon-container {
		margin-top: 15px;
	}

	// scss-lint:disable NestingDepth
	.mdl-navigation {
		.mdl-navigation__link {
			&.menu__link {
				position: relative;
				color: $secondary-text-color-light;
				min-height: $link-height;
				line-height: $link-height;
				padding: 0 0 0 $menu-left-paading;
				font-size: 16px;

				&:hover {
					background-color: transparent;
					color: $primary-text-color;
				}

				&.menu__link--active {
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						height: $link-height;
						width: 5px;
						background: $primary-text-color;
					}
				}
			}
		}
	}

	// .menu__link {
	//   color: $secondary-text-color-light;
	//   min-height: 48px;
	//   line-height: 48px;
	//   padding: 0;
	//
	//   &:hover {
	//     color: $primary-text-color;
	//   }
	// }
}
