body.dimmed {
	// Fix for iOS text input cursors when modal is open.
	@include mobile {
		position: fixed;
	}
}

.ui.modal {
	$modal-header-height: 80px;
	$modal-header-height-mobile: 64px;
	$modal-content-padding-vertical: 45px;
	$modal-content-padding-horizontal: 80px;

	&.modal--top {
		top: 40px;
		margin-top: 0 !important;
	}

	.header {
		display: flex;
		align-items: center;
		padding: 0 16px !important;

		.header__title {
			display: flex;
			flex: 3;
			justify-content: center;
			font-size: 18px;
			color: $secondary-text-color;
			text-align: center;
			height: $modal-header-height;
			line-height: $modal-header-height;

			@include mobile {
				height: $modal-header-height-mobile;
				line-height: $modal-header-height-mobile;
				font-size: 16px;
			}
		}

		.header__save {
			display: flex;
			flex: 1;
			justify-content: flex-end;
			font-size: 16px;
			color: $text-color-brandind-dark;
			cursor: pointer;

			span {
				padding: 5px;

				&.spinner {
					display: block;
					width: 70px;
					text-align: right;
					color: $secondary-text-color-light;

					background: {
						size: 30px;
						position: center left;
						image: url('../images/spinner.svg');
						repeat: no-repeat;
						color: $primary-text-color;
					}
				}
			}
		}

		.header__cancel {
			flex: 1;
			font-size: 16px;
			color: $text-color-brandind-dark;
			cursor: pointer;

			.close {
				padding: 5px 5px 5px $icon-size + 4;
				background: {
					image: url('../images/cancel-icon.svg');
					repeat: no-repeat;
					size: $icon-size;
					position: left center;
				};
			}

			.header__cancel--text {
				@include mobile {
					display: none;
				}
			}
		}
	}

	// scss-lint:disable DuplicateProperty
	.content {
		padding: $modal-content-padding-vertical $modal-content-padding-horizontal;
		max-height: 70vh;
		max-height: calc(100vh - #{$modal-header-height} - 2 * #{$modal-content-padding-vertical} - 2 * #{$modal-offset-top});
		overflow: auto;
		// position: relative;

		@include mobile {
			max-height: calc(100vh - #{$modal-header-height-mobile} - 1px - 40px); // 1px - header border-bottom
		}
	}

	.content__error-msg {
		color: $text-color-failure;
		padding-bottom: 10px;
	}

	.content-description {
		font-size: 16px;
		color: $secondary-text-color;
		line-height: 28px;

		.content-description--title {
			font-weight: 600;
		}

		.content-description--text {
			font-weight: 300;
		}
	}

	.actions {
		position: fixed;
		width: 100%;
		box-sizing: border-box;
		background: $background-color-light;
		border-top: 1px solid $border-color-default;
		padding: 30px 16px;
		bottom: 0;

		@include mobile {
			padding: 20px 1rem !important;
		}

		.actions__item {
			display: inline-block;
			cursor: pointer;
			font-size: 14px;
			color: $text-color-dark;
			margin: 0 12px;
			line-height: 20px;

			&.actions__item--icon {
				padding-left: $icon-size + 10px;
				background: {
					size: $icon-size;
					repeat: no-repeat;
					position: left center;
				};
			}

			&.actions__item--not-clickable {
				cursor: auto;
			}
		}
	}
}
