@keyframes flash-message {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

.flash-message {
	$flash-color-success: #E6F0F9;
	$flash-color-error: #E4C7C7;

	font-size: 15px;
	color: $text-color-brandind-dark;
	text-align: center;
	padding: 22px 15px;

	&.flash-message--success {
		background: $flash-color-success;
	}

	&.flash-message--error {
		background: $flash-color-error;
		animation: flash-message 0.2s 2;
	}

	&.flash-message--fixed-to-bottom {
		position: fixed;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
}
