.landing-page {
	$landing-section-height: 500px;
	$landing-section-height-mobile: 580px;

	.landing-page__section {
		min-height: $landing-section-height;
	}

	.landing-page__section-analytics {
		background-color: $background-color-default;

		.landing-page-section__description {
			color: $secondary-text-color;
			margin-top: 0;

			img {
				margin: 60px 0;
			}
		}
	}

	.landing-page__section-offers {
		background-color: $primary-background-color-light;

		.landing-page-section__title {
			color: $primary-text-color;
		}
	}

	.landing-page__section-requests {
		background-color: $primary-background-color-smooth;

		.landing-page-section__title {
			color: $primary-text-color;
		}
	}

	.landing-page__section-results {
		background-color: $primary-background-color-light;

		.landing-page-section__title {
			color: $primary-text-color;
		}
	}
}

.landing-page-section {
	padding: 50px;
	overflow: hidden;

	.landing-page-section__description {
		display: inline-block;
		color: $primary-text-color;
		margin-top: 150px;

		@include mobile {
			margin-top: 0;
		}

		p {
			font-size: 24px;
			line-height: 36px;
			font-weight: 200;

			@include mobile {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	.landing-page-section__container {
		.mdl-grid {
			img {
				max-width: 100%;
			}
		}
	}

	.landing-page-section__title {
		font-weight: 200;
		text-align: center;
		font-size: 36px;
		margin-bottom: 30px;
		width: 100%;

		@include mobile {
			font-size: 24px;
		}

		span {
			font-weight: 600;
		}
	}

	.landing-page-section__subtitle {
		font-weight: 300;
		font-size: 18px;
		line-height: 34px;
	}

	.landing-page-section__placeholder {
		height: 64px;
		width: 100%;
		margin-top: 40px;
		background: {
			repeat: no-repeat;
			position: left center;
		};
	}

	// scss-lint:disable DeclarationOrder
	@include tablet {
		text-align: center;

		.landing-page-section__description {
			display: block;
		}

		.landing-page-section__subtitle {
			font-size: 16px;
		}

		.landing-page-section__placeholder {
			background-position: center;
		}
	}
}
