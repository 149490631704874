.checkbox {
	.mdl-checkbox__box-outline {
		border: 2px solid $secondary-background-color-dark !important;
	}

	&.is-checked {
		.mdl-checkbox__tick-outline {
			background-color: $secondary-background-color-dark !important;
			padding-bottom: 1px;
		}
	}

	&.checkbox--light {
		.mdl-checkbox__box-outline {
			border: 2px solid $branding-background-color-light !important;
		}

		&.is-checked {
			.mdl-checkbox__tick-outline {
				background-color: $branding-background-color-light !important;
				padding-bottom: 1px;
			}
		}
	}
}
