.terms-page {
	display: flex;
	padding: 20px;
}

.terms-menu {
	min-width: 320px;

	@include mobile {
		display: none;
	}

	.terms-menu__list {
		margin: 0;
		padding: 0;
		list-style: none;

		.terms-menu__list--links {
			color: $text-color-dark;
			text-decoration: none;
		}
	}
}
