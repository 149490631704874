.new-business-container {
	padding: 100px 0;
	background-color: $background-color-light;
}

.new-business-form {
	$form-width: 720px;

	max-width: $form-width;
	margin: 0 auto;
}
