.tooltip {
	padding: 20px 17px 13px 17px;
	background: $background-color-light;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
	border-radius: $border-radius-small;
	font-size: 14px;
	color: $secondary-text-color;
	line-height: 24px;
	overflow: hidden;
	white-space: pre-wrap;
	text-align: left;
	max-width: 270px;
}

.tooltip-menu {
	.tooltip-menu__item--icon {
		margin-right: 18px;

		.svg-main {
			opacity: 0.54;
		}
	}
}
