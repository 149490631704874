.recipients {
	display: flex;
	flex-flow: row wrap;
}

.recipient {
	$recipient-avatar-size: 32px;

	display: flex;
	flex: 1 1 50%;
	align-items: center;
	margin: 4px 0;

	.recipient__avatar {
		width: $recipient-avatar-size;
		min-width: $recipient-avatar-size;
		height: $recipient-avatar-size;
		border-radius: 100%;
		margin-right: 8px;
		background: {
			image: url('../images/default-user-avatar.svg');
			repeat: no-repeat;
			size: cover;
			position: center;
		};
	}

	.recipient__name {
		font-size: 14px;
		color: $text-color-dark;
		line-height: 17px;
	}
}
