.mdl-textfield {

	&.input-icon {
		padding-left: $icon-size + 20px;
		background: {
			repeat: no-repeat;
			size: $icon-size;
			position: left center;
		};

		.mdl-textfield__label {
			margin-left: $icon-size + 20px;
			box-sizing: border-box;
			width: auto;
		}
	}

	.mdl-textfield__input {
		&.input-icon {
			padding-left: $icon-size + 16px;
			background: {
				repeat: no-repeat;
				size: $icon-size;
				position: left 8px center;
			};
		}
	}
}
