.help-container {
	background: $background-color-light;
}

.help {
	$help-font-size: 18px;

	max-width: $public-content-max-width;
	padding: 0 20px;
	margin: 50px auto;
	color: $secondary-text-color;
	font-size: $help-font-size;
	line-height: 27px;
	font-weight: 300;

	.help--hightlighted-text {
		color: $text-color-brandind-dark;
	}

	.help--bold-text {
		display: inline-block;
		font-weight: 400;
	}

	.help__divider {
		border-bottom: 1px solid $border-color-dark;
		margin: 30px 0;
	}

	.help__content {
		line-height: 22px;
	}

	.help__paragraf {
		padding: 30px 0;

		&.help__paragraf--no-bottom-spacing {
			padding-bottom: 0;
		}
	}

	.help__title {
		font-size: 28px;
		font-weight: 300;
		color: $text-color-dark;
		padding: 30px 0;
	}

	.help__list-title {
		padding: 30px 0;
	}

	.help__footer {
		margin-top: 30px;
	}

	.help__list {
		margin: 10px 0 0;
		padding-left: 60px;
		font-size: $help-font-size;
		font-weight: 300;

		@include mobile {
			padding-left: 50px;
		}
	}
}

.help-info {
	margin-bottom: 15px;

	.help-info__label {
		line-height: 27px;
		margin-bottom: 5px;
	}

	.help-info__content {
		color: $text-color-brandind-dark;
		font-weight: 400;
	}
}

.help-footer {
	line-height: 27px;

	.help-footer__item {
		&.help-footer__item--separated {
			margin-bottom: 20px;
		}
	}
}
