.header {
	.header__mobile-icon {
		height: $icon-size;
		width: $icon-size;
		padding: 10px;
		cursor: pointer;
		background: {
			image: url('../images/menu-icon.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: center;
		};
	}

	.header__account-menu {
		font-weight: 400;
		font-size: 16px;
		color: $secondary-text-color-light;
		line-height: 19px;
		text-transform: none;
	}

	.header__left-menu {
		display: flex;
		align-items: center;

		.header__left-menu--arrow {
			display: inline-block;
			width: $icon-size;
			height: $icon-size;
			cursor: pointer;
			background: {
				image: url('../images/arrow-left-with-tail.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: center;
			}
		}

		.header__left-menu--label {
			max-width: 200px;
			padding-left: $icon-size;
			display: inline-block;
			font-size: 16px;
			color: $primary-text-color;
			line-height: 19px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.mdl-layout {
	min-height: 100vh;

	.mdl-layout__header-row {
		min-height: $header-height;
		background: $primary-background-color;
		border-bottom: 1px solid $border-color-branding-darker;
		padding: 0 55px;

		@include mobile {
			min-height: $header-height-mobile;
			padding: 0 10px 0 20px;
		}
	}

	.mdl-layout__drawer-button {
		display: none;
	}
}

.mdl-navigation {
	&.mdl-navigation--full-height {
		min-height: $header-height;

		@include mobile {
			min-height: $header-height-mobile;
		}
	}

	.mdl-navigation__link {
		&.mdl-navigation__link--full-height {
			min-height: $header-height;
			line-height: $header-height;

			@include mobile {
				min-height: $header-height-mobile;
				line-height: $header-height-mobile;
			}
		}
	}
}

.menu {
	.menu__link {
		font-size: 16px;
		color: $secondary-text-color-light;
		position: relative;

		&:hover {
			color: $primary-text-color;
		}

		&.menu__link--active {
			color: $primary-text-color;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				height: 5px;
				width: 100%;
				background: $primary-text-color;
			}
		}
	}
}

.account-menu {
	position: relative;
	color: $secondary-text-color-light;
	font-size: 16px;
	min-height: $header-height;
	line-height: $header-height;

	&:hover {
		color: $primary-text-color;

		.svg-background-color {
			fill: $primary-text-color;
		}
	}

	.svg-background-color {
		fill: $secondary-text-color-light;
	}

	.mdl-button {
		&:hover {
			background: transparent;
		}
	}
}
