// colors
$primary-background-color: #0066CC;
$primary-background-color-light: #4C97ED;
$primary-background-color-smooth: #82B6F3;
$primary-background-color-lighter: #DAE4EE;
$primary-background-color-transparent: rgba(0, 102, 204, 0.1);
$secondary-background-color: #4C4C4C;
$secondary-background-color-dark: #757575;
$secondary-background-color-light: #B2B2B2;
$secondary-background-color-lighter: #F2F2F2;
$background-color-light: #FFFFFF;
$background-color-default: #F4F0F1;
$background-color-avatar: rgba(51, 51, 51, 0.9);
$background-color-input: #F7F7F7;
$dimmer-bacground-color: rgba(0, 102, 204, 0.9);
$background-color-hover: #F3F8FE;
$branding-background-color-light: #C1D6F0;
$green-background-color: #47D132;
$green-background-color-lighter: #9BFE8C;

$primary-text-color: #FFFFFF;
$blue-text-color: #0066CC;
$primary-text-color-transparent: rgba(255, 255, 255, 0.5);
$text-color-light-dark: #FDFDFD;
$secondary-text-color: #414141;
$secondary-text-color-light: #C1D6F0;
$secondary-text-color-dark: #B7D5F7;
$text-color-dark: #555555;
$text-color-dark-lighter: #A09FA0;
$text-color-dark-light: #C6C5C6;
$text-color-branding: #2775D1;
$text-color-branding-light: #4C97ED;
$text-color-branding-lightest: #7EB1E7;
$text-color-brandind-dark: #0066CC;
$text-color-filter: #8EB4E3;
$text-color-failure: #D0021B;
$text-color-active: #50D739;
$text-color-warning: #F5A623;
$text-color-published: #96CDBB;

$border-color-default: #EBEBEB;
$border-color-dark: #C0C0C0;
$border-color-branding-darker: #1F78D2;
$border-color-branding-dark: #80B2E5;
$border-color-branding-light: #B7D5F1;

$max-content-width: 1440px;
$max-carousel-width: 1440px;
$max-table-width: 1440px;
$table-side-offest: 135px;
$table-side-offest-mobile: 0;
$header-height: 80px;
$header-height-mobile: 62px;
$footer-height: 55px;
$footer-height-mobile: 110px;
$footer-vertical-padding: 25px;
$footer-border-width: 1px;
$footer-real-height: $footer-height + $footer-border-width + $footer-vertical-padding * 2;
$footer-mobile-real-height: $footer-height + $footer-border-width + $footer-vertical-padding;
$card-header-height: 72px;
$max-modal-width: 740px;
$modal-offset-top: 50px;
$clients-table-header-height: 113px;
$requests-table-header-height: 113px;
$table-prefilters-height: 56px;
$clients-prefilters-height: 129px;
$public-content-max-width: 800px;

$border-radius-default: 4px;
$border-radius-small: 2px;

// icons size
$icon-size: 24px;
$logo-size: 150px;
$mobile-logo-size: 50px;
$avatar-size: 100px;
$logo-size-mobile: 38px;
$logo-size-footer-mobile: 32px;

$card-width: 270px;
$card-spacing: 15px;

// mdl columns breakpoints
// $eight-columns-breakpoint: 480px; // all under 480px has 4 columns
$twelve-columns-breakpoint: 840px; // all above 839px has 12 columns
