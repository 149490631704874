@import 'vendors';
@import 'fonts';
@import 'vars';
@import 'mixins';
@import 'common';
@import 'components/button';
@import 'components/input';
@import 'components/select';
@import 'overrides';
@import 'components/header';
@import 'components/footer';
@import 'components/table';
@import 'components/flash-message';
@import 'components/tooltip';
@import 'components/summary';
@import 'components/search-component';
@import 'login';
@import 'register';
@import 'components/card';
@import 'photos';
@import 'landing';
@import 'components/form';
@import 'components/modal';
@import 'components/toaster';
@import 'components/checkbox';
@import 'paywall';
@import 'clients';
@import 'businesses';
@import 'business-profile';
@import 'verification';
@import 'new-business';
@import 'components/verification-method';
@import 'reviews';
@import 'components/review';
@import 'components/recipients';
@import 'components/table-row-card';
@import 'requests';
@import 'admin-businesses';
@import 'admin-reports';
@import 'faq';
@import 'help';
@import 'menu-drawer';
@import 'not-found';
@import 'overrides/slick';
@import 'overrides/mdl';
@import 'terms';

body {
	font-family: Montserrat, Helvetica, sans-serif;
	line-height: normal;
	margin: 0;
	-webkit-font-smoothing: antialiased;
}
