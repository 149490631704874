@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-ultralight-webfont.eot');
	src: url('../fonts/montserrat/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/montserrat/montserrat-ultralight-webfont.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-ultralight-webfont.woff') format('woff'),
	url('../fonts/montserrat/montserrat-ultralight-webfont.ttf') format('truetype'),
	url('../fonts/montserrat/montserrat-ultralight-webfont.svg#montserratultra_light') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-light-webfont.eot');
	src: url('../fonts/montserrat/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-light-webfont.woff') format('woff'),
	url('../fonts/montserrat/montserrat-light-webfont.ttf') format('truetype'),
	url('../fonts/montserrat/montserrat-light-webfont.svg#montserratlight') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-regular-webfont.eot');
	src: url('../fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
	url('../fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
	url('../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-semibold-webfont.eot');
	src: url('../fonts/montserrat/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-semibold-webfont.woff') format('woff'),
	url('../fonts/montserrat/montserrat-semibold-webfont.ttf') format('truetype'),
	url('../fonts/montserrat/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
	font-weight: 600;
	font-style: normal;
}
