.paywall {
	color: $primary-text-color;

	small {
		display: block;
		margin: -20px 0 0;
	}

	.summary__title {
		white-space: nowrap;
	}

	.paywall-top {
		max-width: 950px;
		margin: 0 auto;
		padding: 0 10px;

		.big-text {
			margin-top: 50px;
		}

		.lead {
			font-size: 1.35em;
			font-weight: 300;
		}
	}

	.paywall-bottom {
		padding: 50px 10px;
	}

	.blue_bold {
		color: $blue-text-color;
	}

	.mdl-cell {
		color: $text-color-dark;
		background: #EBF3FB;
		border-radius: 15px;
		padding: 20px 15px;

		img {
			max-width: 100%;
		}

		.circle {
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border-radius: 50%;
			font-weight: bold;
			color: $primary-text-color;
			margin: 0 auto;
			background: $primary-background-color;
			display: block;
		}

		h5 {
			font-size: 1.5em;
			line-height: 1.2em;
			border-bottom: 2px solid #CCCCCC;
			padding-bottom: 15px;
		}

		ul {
			padding-left: 20px;
			line-height: 1.4em;

			li {
				margin-bottom: 10px;
			}
		}
	}

	.stripe-button-el {
		margin: 0 auto 20px;
		max-width: 400px;
		width: 100%;
		display: block;
		border-radius: 10px;
		background-image: none;
		box-shadow: none;
		padding: 0;

		span {
			border-radius: 2px;
			height: $table-prefilters-height;
			line-height: $table-prefilters-height;
			box-sizing: border-box;
			font-weight: 600;
			font-size: 16px;
			letter-spacing: 1px;
			background: $green-background-color;
			color: $primary-text-color;
			min-width: 64px;
			max-width: 400px;
			width: 100%;
			padding: 0 8px;
			display: inline-block;
			text-transform: uppercase;
			overflow: hidden;
			will-change: box-shadow, transform;
			transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
			outline: none;
			cursor: pointer;
			text-decoration: none;
			text-align: center;
			vertical-align: middle;
			text-shadow: none;
			box-shadow: none;

			&:hover {
				background: $green-background-color-lighter;
				color: $secondary-background-color;
			}
		}
	}
}
