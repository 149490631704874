$business-verification-max-width: 771px;

.business-verification-container {
	margin: 0 auto;
	max-width: $business-verification-max-width;
	text-align: left;
	padding: 0 20px;
}

.business-verification {
	$business-icon-size: 87px;

	background-color: $background-color-light;
	padding: 70px 0 40px 0;
	text-align: center;

	@include tablet {
		padding-top: 40px;
	}

	&.business-verification--more-top-spacing {
		padding-top: 140px;
	}

	.business-verification__image-container {
		height: $business-icon-size;
		width: $business-icon-size;
		margin: 0 auto 20px auto;
		background: {
			image: url('../images/unverified-business-icon.svg');
			repeat: no-repeat;
			size: $business-icon-size;
			position: center;
		}
	}

	.business-verification__details {
		font-weight: 200;

		&.business-verification__details--large {
			font-size: 28px;
			padding: 20px 0;
			color: $secondary-text-color;
		}

		&.business-verification__details--small {
			font-size: 15px;
			padding: 3px 0;
			color: $text-color-dark-light;
		}

		&.business-verification__details--red {
			color: $text-color-failure;
		}

		&.business-verification__details--bold {
			font-weight: 400;
		}
	}

	.verification-method {
		.business-title {
			font-weight: 300;
			font-size: 28px;
		}
	}
}

.verification-methods {
	padding: 90px 0 100px 0;
	background-color: $background-color-hover;

	@include tablet {
		padding: 72px 0 40px 0;
	}

	.verification-methods__title {
		font-weight: 200;
		font-size: 28px;
		color: $secondary-text-color;
		margin-bottom: 55px;

		@include mobile {
			font-size: 24px;
			margin-bottom: 40px;
		}
	}

	.verification-methods__label {
		color: $text-color-branding-light;
	}
}

.verification-assistance-container {
	background-color: $background-color-light;
	padding: 56px 0;
	text-align: center;
}

.verification-assistance {
	$assistance-icon-size: 64px;

	display: inline-block;
	color: $secondary-text-color;
	padding-left: $assistance-icon-size + 36px;
	background: {
		image: url('../images/assistance-icon.svg');
		repeat: no-repeat;
		size: $assistance-icon-size;
		position: left top;
	}

	.verification-assistance__title {
		font-weight: 300;
		font-size: 28px;
		text-align: left;
		margin-bottom: 25px;
	}

	.verification-assistance__subtitle {
		font-size: 16px;
		line-height: 33px;

		.verification-assistance__subtitle--highlighted {
			color: $text-color-brandind-dark;
		}

		.verification-assistance__subtitle--link {
			cursor: pointer;
		}
	}
}

.verification-info {
	color: $text-color-dark;
	font-size: 16px;
	margin-bottom: 25px;

	.verification-info__title {
		font-weight: 500;
		margin-bottom: 10px;
	}

	.verification-info__description {
		font-weight: 300;

		.verification-info__description--highlighted {
			font-weight: 400;
			color: $text-color-brandind-dark;
		}
	}
}

.resend-code-container {
	font-weight: 400;
	font-size: 14px;
	color: $secondary-text-color-light;
	text-align: center;
	width: 100%;
}
