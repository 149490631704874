.admin-reports-table {
	.admin-reports-table__table-row {
		cursor: pointer;
	}
}

.summary {
	.summary__wrapper {
		&.summary__wrapper--reports {
			&.summary__wrapper--table-prefilters {
				min-height: 415px;

				@include tablet {
					min-height: 360px;
				}
			}
		}
	}
}
