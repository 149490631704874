.popup {
	position: absolute;
	height: 200px;
	width: 400px;
	top: 20%;
	left: 15%;
	background: white;
	box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	padding: 20px;
	overflow: auto;
	z-index: 2;

	@include mobile {
		margin-left: -50px;
		width: 150px;
	}

	.popup__item {
		padding: 10px;
		border-bottom: 1px solid;
		border-bottom-color: rgba(34, 36, 38, 0.14902);
		cursor: pointer;

		&:hover {
			background-color: #F3F8FE;
			transition: background-color 0.2s ease-in-out;
		}
	}
}

.category-row {
	display: flex;
	justify-content: center;
	align-items: center;

	.category-row__name {
		width: 150px;
		@include mobile {
			width: 90px;
		}
	}
}

.form {
	$input-height: 44px;
	$input-padding: 16px;

	.form-row {
		display: flex;
		margin: 26px 0;

		@include tablet {
			margin: 40px 0;

			&:last-child {
				margin-bottom: 120px;
			}
		}

		&.form-row--smaller-bottom-spacing {
			margin-bottom: 13px;
		}

		&.form-row--smaller-top-spacing {
			margin-top: 13px;
		}

		&.form-row--bottom-margin {
			margin-bottom: 70px;
		}

		&:first-of-type {
			margin-top: 0;
		}

		.form-row__cell {
			display: flex;
			flex: 1;

			&:first-child {
				margin-right: 15px;
			}

			&:last-child {
				margin-left: 15px;
			}

			&:only-child {
				margin: 0;
			}
		}
	}

	.form__form-element-wrapper {
		width: 100%;
	}

	.form__label {
		font-size: 15px;
		color: $text-color-dark-lighter;
		line-height: 20px;
		.form__label--madatory {
			color: $text-color-failure;
		}
	}

	.form__input {
		height: $input-height;
		background-color: $background-color-input;
		border: 0;
		box-shadow: inset 0 -2px 0 0 $border-color-dark;
		padding: 0 0 0 $input-padding;
		box-sizing: border-box;
		font-size: 15px;
		line-height: 20px;
		-webkit-transform: translateZ(0px);

		&.form__input--error {
			box-shadow: inset 0 -2px 0 0 red;
		}

		&:focus {
			background-color: $background-color-hover;
			box-shadow: inset 0 -2px 0 0 $primary-background-color;
			color: $text-color-brandind-dark;
		}
	}

	// FIXME: remove rounded edges from select
	.form__select {
		width: 100%;
		height: $input-height;
		color: $text-color-dark;
		background: $background-color-input;
		border: 0;
		box-shadow: inset 0 -2px 0 0 $border-color-dark;
		padding: 0 0 0 $input-padding;
		font-size: 15px;
	}

	.form__disabled-input {
		font-size: 15px;
		color: $secondary-text-color;
		line-height: 20px;
	}

	.form__textarea {
		padding: $input-padding;
		box-sizing: border-box;
		height: 200px;
		font-size: 15px;
		color: $text-color-dark;
		line-height: 24px;
		background: $background-color-input;
		box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.22);
		-webkit-transform: translateZ(0px);

		&:focus {
			background: $background-color-hover;
			box-shadow: inset 0 -2px 0 0 $primary-background-color;
			color: $text-color-brandind-dark;
		}
	}

	.form__input-container {
		display: block;
		width: 100%;
		padding: 0;
		margin-top: 10px;
	}

	.form__note {
		font-size: 13px;
		line-height: 24px;
		color: $text-color-dark-lighter;
		margin-top: 10px;
	}
}
