$min-select-width: 200px;
$select-height: 37px;

.select2 {
	&.select2-container {
		min-width: $min-select-width;

		.select2-selection__placeholder {
			color: $text-color-filter;
		}
	}

	.select2-selection {
		background-color: transparent;
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid $text-color-filter;
		outline: none;
		background: {
			image: url('../images/arrow-down.svg');
			repeat: no-repeat;
			position: right center;
			size: $icon-size;
		}

		.select2-selection__rendered {
			color: $text-color-filter;
			padding-left: 0;
		}
	}

	.select2-selection__arrow {
		display: none;
	}
}

.select2-dropdown {
	border: 0;
	border-radius: $border-radius-small;
	box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);

	.select2-search__field {
		font-family: Montserrat, Helvetica, sans-serif;
		font-size: 14px;

		&:focus {
			outline: none;
		}

	}
}

.select2-results {
	.select2-results__option {
		padding-top: 12px;
		padding-bottom: 12px;

		&.select2-results__option--highlighted {
			background-color: $background-color-hover;
			color: $text-color-dark;
		}
	}
}
