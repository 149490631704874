.faq-container {
	background: $background-color-light;
}

.faq {
	font-size: 18px;
	font-weight: 300;
	max-width: $public-content-max-width;
	padding: 0 20px;
	margin: 30px auto;
	color: $secondary-text-color;
	line-height: 27px;
}

.faq-item {
	position: relative;
	border-bottom: 1px solid $border-color-dark;
	padding: 30px 0;

	&:last-child {
		border-bottom: 0;
	}

	.faq-item__question {
		padding-right: 40px;
		cursor: pointer;
		position: relative;

		&::after {
			content: '';
			height: $icon-size;
			width: $icon-size;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -$icon-size / 2 - 1px;
			transition: transform 0.5s ease;
			background: {
				image: url('../images/expand-arrow.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: center;
			}
		}
	}

	.faq-item__answer {
		padding-top: 20px;
	}

	.faq-item__answer-container {
		height: 0;
		overflow: hidden;
		transition: height 0.5s ease-out;
	}

	&.faq-item--expanded {
		.faq-item__question {
			&::after {
				transform: rotate(180deg);
			}
		}
	}
}
