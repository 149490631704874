.photos-container {
	background-color: $primary-background-color-transparent;
	text-align: center;
	padding: 80px 0 100px 0;

	.photos-container__title {
		font-size: 28px;
		font-weight: 300;
		color: $secondary-text-color;
	}

	.photos-container__photos {
		max-width: $max-carousel-width;
		margin: 34px auto 0 auto;
	}
}

.photos {
	$max-photo-size: 270px;
	$photo-overlay-color: rgba(0, 0, 0, 0.6);

	.photos__overlay {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $border-radius-default;
		background-color: $photo-overlay-color;
		box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);
		opacity: 0;
		transition: visibility 0s, opacity 0.3s ease;

		.photos__overlay-icon {
			position: absolute;
			top: 15px;
			right: 15px;
			height: 30px;
			width: 30px;
			cursor: pointer;
			background: {
				image: url('../images/delete-icon.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: center;
			};
		}
	}

	.photos__photo {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 15px;
		height: $max-photo-size;
		width: $max-photo-size;
		text-align: center;
		vertical-align: bottom;
		border-radius: $border-radius-default;

		@include mobile {
			margin: 15px auto;
		}

		&.photos__photo--add-new {
			cursor: pointer;
			border: 1px dashed $border-color-dark;
			background: {
				image: url('../images/camera-icon.svg');
				repeat: no-repeat;
				size: 3 * $icon-size;
				position: center;
			};

			&.is-loading {
				background: {
					image: url('../images/spinner.svg') !important;
					size: 50px;
				}
			}
		}

		// scss-lint:disable NestingDepth
		&.photos__photo--full {
			background: {
				repeat: no-repeat;
				size: cover;
				position: center;
			};

			&:hover {
				.photos__overlay {
					visibility: visible;
					opacity: 1;
				}
			}

			&:focus {
				.photos__overlay {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	.photos__browse-input {
		width: $max-photo-size;
		height: $max-photo-size;
		opacity: 0;
		cursor: pointer;
	}
}
