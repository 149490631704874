.status-toast {
  position: fixed;
  width: 300px;
  left: calc(50% - 150px);
  margin: 0 auto;
  padding: 15px 5px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  border-radius: 0 0 5px 5px;
  z-index: 9999;

  &.status-toast-success {
    background-color: #36b52c;
  }
  &.status-toast-error {
    background-color: #fb4343;
  }
  &.status-toast-info {
    background-color: #43cafb;
  }
}
