.card {
	$card-height: 250px;
	$card-left-padding: 40px;
	$card-right-padding: 40px;
	$card-title-vertical-padding: 16px;
	$card-icon-padding: 10px;

	$cards-1-breakpoint: 600px;
	$cards-2-breakpoint: $twelve-columns-breakpoint;
	$cards-3-breakpoint: 1200px;

	$icon-color-light: #FFFFFF;
	$icon-color-dark: #000000;

	display: inline-block;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
	transition: box-shadow 0.3s ease;
	margin: 0 $card-spacing $card-spacing * 2;
	width: $card-width;
	// height: $card-height;
	// max-height: $card-height;
	border-radius: $border-radius-default;
	font-size: 15px;

	@media (max-width: ($cards-2-breakpoint - 1)) {
		display: block;
		width: auto;
	}

	@include desktop {
		.card__edit-icon {
			display: none;
		}

		&:hover {
			box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);

			.card__edit-icon {
				display: block;
			}
		}
	}

	&.card--double-height {
		.card__scrollable-container {
			height: 2 * $card-height + 2 * $card-spacing - $card-header-height + 4px;
		}
	}

	&.card--double-width {
		@media (min-width: $cards-2-breakpoint) {
			width: 2 * $card-width + 2 * $card-spacing;
		}
	}

	&.card--with-tooltip {
		overflow: visible;
	}

	.card__scrollable-container {
		height: $card-height - $card-header-height;
		overflow-y: auto;
	}

	.card__title {
		position: relative;
		height: $card-header-height;
		color: $secondary-text-color;
		letter-spacing: 0.5px;
		font-weight: 600;
		padding: $card-title-vertical-padding $card-right-padding $card-title-vertical-padding $card-left-padding;
		border-bottom: 1px solid $border-color-default;

		background: {
			color: $background-color-light;
			repeat: no-repeat;
			size: $icon-size;
			position: left 20px center;
		};

		&.card__title--first-row {
			color: $text-color-light-dark;
			background-color: $primary-background-color-light;
			border: 0;
		}

		&.card__title--description {
			.svg-color {
				fill: $icon-color-light;
			}

			@media (max-width: ($cards-3-breakpoint - 1)) {
				color: $secondary-text-color;
				background-color: $background-color-light;
				border-bottom: 1px solid $border-color-default;

				.svg-color {
					fill: $icon-color-dark;
				}
			}
		}

		&.card__title--primary-contact {
			.svg-color {
				fill: $icon-color-light;
			}

			@media (max-width: ($cards-2-breakpoint - 1)) {
				color: $secondary-text-color;
				background-color: $background-color-light;
				border-bottom: 1px solid $border-color-default;

				.svg-color {
					fill: $icon-color-dark;
				}
			}
		}
	}

	.card__icon {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: - ($icon-size / 2 + $card-icon-padding);
		padding: $card-icon-padding;
	}

	.card__edit-icon {
		position: absolute;
		right: 12px;
		top: 50%;
		margin-top: - ($icon-size / 2 + $card-icon-padding);
		padding: $card-icon-padding;
		cursor: pointer;
		background: {
			image: url('../images/edit-icon.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: center;
		};
	}

	.card__content {
		word-wrap: break-word;
		padding: 0 15px;
		width: auto;
		background-color: $background-color-light;
	}

	.card__item {
		color: $secondary-text-color;
		border-bottom: 1px solid $border-color-default;
		padding: 18px 0;

		&.inactive {
			color: $text-color-dark-light;
		}
	}

	.card__info-text {
		padding: 20px 0;
		font-size: 13px;
		color: $secondary-text-color;

		&.card__info-text--empty {
			color: $text-color-dark-lighter;
			font-size: 15px;
			line-height: 25px;
			font-weight: 400;
		}
	}
}
