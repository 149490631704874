.verification-method {
	$verification-method-icon-size: 64px;

	color: $secondary-text-color;
	padding-left: $verification-method-icon-size + 36px;
	padding-right: 85px;
	margin-bottom: 50px;
	background: {
		repeat: no-repeat;
		size: $verification-method-icon-size;
		position: left top;
	}

	@include mobile {
		padding-left: $verification-method-icon-size + 12px;
		padding-right: 0;
		margin-bottom: 40px;
	}

	&:last-child {
		.verification-method__button-container {
			border-bottom: 0;
		}
	}

	&.verification-method--message {
		background-image: url('../images/verification-message-icon.svg');
	}

	&.verification-method--phone {
		background-image: url('../images/verification-phone-icon.svg');
	}

	&.verification-method--claimed {
		background-image: url('../images/claimed-icon.svg');
	}

	.verification-method__title {
		font-size: 18px;
		margin-bottom: 15px
	}

	.verification-method__description {
		font-weight: 300;
		font-size: 16px;
		line-height: 33px;

		.verification-method__description--highlighted {
			font-weight: 400;
			color: $text-color-brandind-dark;
		}
	}

	.verification-method__button-container {
		padding: 25px 0 45px 0;
		border-bottom: 1px solid $border-color-branding-light;

		@include mobile {
			padding: 20px 0 36px 0;
		}
	}
}
