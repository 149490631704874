.button {
	$button-default-height: 56px;
	$button-default-height-mobile: 50px;
	$facebook-branding-color: #3B5998;

	font-size: 14px;
	letter-spacing: 1px;
	background-color: $text-color-brandind-dark;
	color: $primary-text-color;

	&:hover {
		background-color: $text-color-branding-light;
	}

	// added important because some properties were overriden for some buttons
	&.spinner {
		color: rgba(0, 0, 0, 0);
		background: {
			image: url('../images/spinner.svg') !important;
			repeat: no-repeat !important;
			size: 30px !important;
			position: center !important;
			color: rgba(76, 151, 237, 0.19) !important;
		}
	}

	&.button--default {
		border-radius: $border-radius-small;
		height: $button-default-height;
		line-height: $button-default-height;
		box-sizing: border-box;
		font-weight: 600;

		@include mobile {
			height: $button-default-height-mobile;
			line-height: $button-default-height-mobile;
		}
	}

	&.button--active {
		background: $text-color-active;
		text-transform: none;
		font-weight: 400;
		padding: 0 60px;
	}

	&.button--on-primary-background {
		&:hover {
			background-color: $text-color-brandind-dark;
			opacity: 0.7;
		}
	}

	&.button--plain {
		color: $text-color-brandind-dark;
		background-color: transparent;

		&:hover {
			background: none;
		}
	}

	&.button--transparent {
		background-color: transparent;
		color: $text-color-branding-light;
		border: 1px solid $text-color-branding-light;
	}

	&.button--light {
		background: $primary-background-color-light;

		&:hover {
			background: $primary-background-color-light;
			opacity: 0.7;
		}
	}

	&.button--full-width {
		width: 100%;
	}

	&.button--spacing {
		margin: 40px 0;
	}

	&.button--spacing-bottom {
		margin: 0 0 40px;
	}

	&.button--padding {
		padding: 0 26px;
	}

	&.button--multi-line {
		height: auto;
		line-height: 1.2em;
	}

	&.button--facebook {
		background-color: $facebook-branding-color;

		.button--facebook-icon {
			padding: 10px 0 10px ($icon-size + 16px);
			background: {
				image: url('../images/facebook-icon.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: left center;
			};
		}
	}
}

.mdl-button {
	&.mdl-button--no-hover {
		&:hover {
			background-color: transparent;
		}
	}
}
