.not-found-container {
	background: $primary-background-color;
	min-height: calc(100vh - #{$header-height} - #{$footer-real-height});
	padding: 0 50px;

	@include mobile {
		min-height: calc(100vh - #{$header-height-mobile} - #{$footer-mobile-real-height});
	}
}

.not-found-section {
	padding: 140px 0 120px;
	text-align: center;

	@include mobile {
		padding: 70px 0;
	}

	.not-found-section__title {
		font-weight: 200;
		font-size: 64px;
		color: $primary-text-color;

		@include mobile {
			font-size: 40px;
		}

		.not-found-section__title--smooth {
			color: $text-color-branding-lightest;
		}
	}

	.not-found-section__image {
		$not-found-image-size: 250px;

		margin: 50px 0 75px 0;
		height: $not-found-image-size + 50px;
		background: {
			image: url('../images/404.svg');
			repeat: no-repeat;
			size: $not-found-image-size;
			position: center;
		};
	}

	.not-found-section__back-container {
		font-size: 16px;
	}

	.not-found-section__link {
		text-decoration: none;
		color: $primary-text-color;
	}
}
