.cards-container {
	.card {
		.card__description-content {
			line-height: 24px;
		}
	}
}

.cards--business-info {
	.card__title--place {
		> svg {
			width: 20px;
			height: 20px;
			margin-left: 5px;

			.svg-color {
				fill: #FF0000;
				fill-opacity: 0.8;
			}
		}
	}
}
