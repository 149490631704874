.footer {
	background: $primary-background-color;
	border-top: $footer-border-width solid $border-color-default;
	padding: $footer-vertical-padding 135px;

	@include mobile {
		padding: $footer-vertical-padding 20px 0 20px;
	}

	.footer__start {
		text-align: center;
	}

	&__get-the-app {
		margin-top: 30px;

		a {
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.mdl-mini-footer {
		background: $primary-background-color;
	}

	.footer__right-section {
		align-items: center;
		height: $footer-height;

		@include mobile {
			align-items: flex-start;
			height: $footer-height-mobile;
		}
	}

	.footer__left-section {
		align-items: center;
		height: $footer-height;

		@include mobile {
			align-items: flex-start;
			height: $footer-height-mobile;
		}
	}

	.footer__logo {
		text-decoration: none;

		&.branding {
			font-size: 16px;
			color: $text-color-branding-light;

			@include mobile {
				min-height: $logo-size-footer-mobile;
				line-height: $logo-size-footer-mobile;
				padding-left: $logo-size-footer-mobile + 12px;
				background-size: $logo-size-footer-mobile;
				font-size: 14px;
			}
		}

		img {
			width: 179px;
		}
	}

	.footer__links {
		font-size: 15px;
		color: $primary-text-color;

		@include mobile {
			display: block;
			font-size: 14px;
			margin-top: 4px;
		}
	}

	.footer__link {
		position: relative;
		margin: 0 30px;
		line-height: 24px;
		font-weight: 200;
		cursor: pointer;

		@include mobile {
			margin: 7px 0 7px 7px;

			&:first-child {
				margin-top: 0;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&::after {
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s, opacity 0.3s linear;
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			bottom: -2px;
			height: 3px;
			background: $text-color-branding;
		}

		&:hover {
			&::after {
				visibility: visible;
				opacity: 1;
			}
		}

		a {
			font-weight: 200;
		}

		&.footer__link--bold {
			a {
				font-weight: 600;
			}
		}
	}
}
