.main-content {
	display: flex;
	flex-direction: column;
	background-color: $secondary-background-color-lighter;
}

.hidden {
	display: none;
}

.hidden-mobile {
	@include mobile {
		display: none !important; // scss-lint:disable ImportantRule
	};
}

.hidden-desktop {
	@include desktop {
		display: none !important; // scss-lint:disable ImportantRule
	};
}

.mdl-grid--no-top {
	padding-top: 0;
}

.mdl-cell--no-top {
	margin-top: 0;
}

.mdl-container__card {
	justify-content: center;

	@media (min-width: $twelve-columns-breakpoint) {
		.mdl-cell__card--fix-width-1 {
			min-width: $card-width + 2 * $card-spacing;
			max-width: $card-width + 2 * $card-spacing;
		}

		.mdl-cell__card--fix-width-2 {
			min-width: 2 * $card-width + 4 * $card-spacing;
			max-width: 2 * $card-width + 4 * $card-spacing;
		}
	}
}

.profile-container {
	background-color: $secondary-background-color-lighter;
}

.small-text {
	font-size: 10px;
	font-weight: normal;
}

.big-text {
	font-size: 24px;
	margin: 20px 0;
	color: $primary-text-color;
}

.branding {
	min-height: $header-height;
	line-height: $header-height;
	padding-left: $logo-size + 16px;
	font-size: 20px;
	font-weight: 600;
	color: $primary-text-color;

	background: {
		image: url('../images/whoweuse.svg');
		repeat: no-repeat;
		size: $logo-size;
		position: left center;
	};

	@include mobile {
		min-height: $header-height-mobile;
		line-height: $header-height-mobile;
		padding-left: $logo-size-mobile + 12px;
		background-size: $logo-size-mobile;
		font-size: 15px;

		background: {
			image: url('../images/logo.svg');
			repeat: no-repeat;
			size: $mobile-logo-size;
			position: left center;
		};

		span {
			display: none;
		}
	}

	.footer & {
		background-position: left top;
	}
}

.list-item {
	cursor: pointer;
	font-family: Montserrat, Helvetica, sans-serif;

	&.ui-state-active {
		border: 0 none;
		font-weight: normal;
		margin: 0;
	}

	&:hover {
		background: $background-color-hover;
	}

	&.list-item--not-clickable {
		&:hover {
			background: $background-color-light;
			cursor: auto;
		}
	}

	&.list-item--spinner {
		height: 65px;
		background: {
			image: url('../images/spinner.svg');
			size: $icon-size;
			repeat: no-repeat;
			position: left 70px center;
		}

		@include mobile {
			background-position: left 40px center;
		}
	}

	.list-item__description {
		padding: 12px 0 12px 65px;
		color: $secondary-text-color;
		background: {
			image: url('../images/place-dark-icon.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: left 24px center;
		};
	}

	&.list-item--empty {
		.list-item__note {
			font-weight: 300;
			font-size: 16px;
			color: $text-color-dark-lighter;
			padding: 22px 70px;

			@include mobile {
				padding: 22px 40px;
			}
		}

		.list-item__description {
			background-image: url('../images/plus-icon.svg');
		}

		.list-item__address {
			color: $text-color-brandind-dark;
		}
	}

	.list-item__name {
		font-size: 16px;
		line-height: 19px;
	}

	.list-item__address {
		opacity: 0.5;
		font-size: 13px;
		line-height: 16px;
		margin-top: 4px;
	}

	.list-item__claimstate {
		font-size: 12px;
		margin-left: 10px;

		&.verified {
			color: $text-color-active;
		}

		&.claimed {
			opacity: 0.5;
		}
	}
}

.link {
	&.link--without-decoration {
		text-decoration: none;
	}
}

.breadcrump {
	background: {
		image: url('../images/arrow-right.svg');
		repeat: no-repeat;
		size: $icon-size;
		position: center;
	};
}

.icon {
	&.icon--mail {
		background-image: url('../images/mail-icon.svg');
	}

	&.icon--lock {
		background-image: url('../images/lock-icon.svg');
	}

	&.icon--flag {
		// background-image: url('../images/flag-icon.svg');
	}

	&.icon--delete-dark {
		background-image: url('../images/delete-dark-icon.svg');
	}

	&.icon--search {
		background-image: url('../images/search-icon.svg');
	}
}

.text-center {
	text-align: center;
}
