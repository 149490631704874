.businesses {
	$business-width: 270px;
	$business-side-margin: 15px;
	$business-container-width: 4 * ($business-width + 2 * $business-side-margin);

	padding-top: 80px;
	padding-bottom: 100px;
	background-color: $primary-background-color-lighter;

	.businesses__container {
		max-width: $business-container-width;
		margin: 0 auto;

		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-wrap: wrap;

		@include mobile {
			padding: 0 20px;
		}
	}

	.businesses__title {
		font-weight: 200;
		font-size: 28px;
		color: $secondary-text-color;
		text-align: center;
	}

	.businesses__empty-image {
		$empty-business-image-size: 180px;

		height: $empty-business-image-size;
		margin-top: 80px;
		margin-bottom: 40px;
		background: {
			image: url('../images/default-business-image.svg');
			repeat: no-repeat;
			size: auto $empty-business-image-size;
			position: center;
		}
	}

	.businesses__empty-message {
		text-align: center;
		font-size: 16px;
		color: $text-color-dark-lighter;
	}

	.business {
		display: inline-block;
		margin: 30px $business-side-margin;
		width: $business-width;
		vertical-align: middle;
		text-decoration: none;
		box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
		transition: box-shadow 0.3s ease;

		@include tablet {
			margin: 15px $business-side-margin;
		}

		@include mobile {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}

		&:hover {
			box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);

			@include mobile {
				box-shadow: none;
			}

			// scss-lint:disable NestingDepth
			.business__info {
				.business__info--delete-icon {
					display: flex;
				}
			}
		}

		.business__image-container {
			border-top-left-radius: $border-radius-default;
			border-top-right-radius: $border-radius-default;
			height: 190px;
			width: 100%;
			background: {
				color: $background-color-light;
				repeat: no-repeat;
				size: cover;
				position: center;
			}

			button {
				position: relative;
				top: 50%;
				background-color: $background-color-light;
				text-transform: none;
			}
		}

		.business__info {
			display: flex;
			align-items: center;
			background: $background-color-light;
			padding: 22px 20px;
			border-top: 1px solid $border-color-default;
			border-bottom-left-radius: $border-radius-default;
			border-bottom-right-radius: $border-radius-default;

			.business__info--label {
				display: flex;
				flex: 6;
				justify-content: flex-start;
				align-items: center;
				font-weight: 600;
				font-size: 15px;
				color: $secondary-text-color;
				letter-spacing: 0.5px;
				line-height: 19px;
				min-height: 38px;
			}

			.business__info--delete-icon {
				flex: 1;
				height: $icon-size;
				cursor: pointer;
				display: none;
				background: {
					image: url('../images/delete-dark-icon.svg');
					repeat: no-repeat;
					size: $icon-size;
					position: right center;
				}

				@include mobile {
					display: flex;
				}
			}
		}
	}
}

.business-shortcuts {
	text-align: center;
	padding: 0 0 25px;
	background: $primary-background-color;

	@media (max-width: 1200px) {
		padding: 0 $table-side-offest-mobile 0 $table-side-offest-mobile;
	}

	.mdl-grid {
		max-width: 1200px;

		.mdl-cell {
			.shortcut-wrapper {
				overflow: hidden;
				margin: 0 auto 20px;
				width: 80%;
				padding: 15px;
				background: $secondary-background-color-lighter;
				border-radius: 4px;

				h4 {
					text-align: center;
					font-size: 21px;
					height: 80px;
					line-height: 28px;
					margin: 0 auto;
					display: flex;
					align-items: center;

					span {
						display: block;
						margin: 0 auto;
					}
				}

				.bs-img-wrapper {
					height: 130px;
					display: flex;
					margin: 15px auto 20px;
					align-items: center;

					img {
						display: block;
						margin: 0 auto;
						max-width: 55%;
					}
				}

				.button {
					height: 45px;
					line-height: 45px;
					float: right;
				}
			}
		}

		&.bs-footer {
			text-align: center;
			color: #FFFFFF;

			h3 {
				@include mobile {
					font-size: 24px;
					line-height: 1.4;
				}
			}

			a {
				color: #FFFFFF;
				text-decoration: none;
			}
		}
	}
}
