.slick-prev:before, .slick-next:before {
	color: $text-color-dark;
	font-weight: bold;
	font-size: 30px;
}

.slick-prev:before {
	content: '<';
}

.slick-next:before {
	content: '>';
}

.slick-arrow {
	top: 135px;
}
