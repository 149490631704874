.admin-businesses-table {
	.table__cell {
		&.table__cell--not-verified {
			color: $text-color-dark-lighter;
		}

		&.table__cell--not-published {
			color: $text-color-warning;
		}

		&.table__cell--published {
			color: $text-color-active;
		}
	}

	.admin-businesses-table__table-row {
		cursor: pointer;
	}
}

.admin-table-wrapper-mobile {
	.table-row-card__action-item {
		&.table__cell--not-verified {
			color: $text-color-dark-lighter;
		}

		&.table__cell--not-published {
			color: $text-color-warning;
		}

		&.table__cell--published {
			color: $text-color-active;
		}
	}
}
