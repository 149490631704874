.reviews-container {
	background-color: $background-color-light;
	padding: 100px 0 60px 0;
	text-align: center;

	@include mobile {
		padding: 50px 0 30px 0;
	}

	.reviews__spinner-container {
		height: $icon-size * 2;
	}

	.reviews__spinner {
		height: 100%;
		background: {
			image: url('../images/spinner.svg');
			size: $icon-size * 2;
			repeat: no-repeat;
			position: center;
		}
	}
}

.reviews {
	.reviews__title {
		font-weight: 300;
		font-size: 28px;
		color: $text-color-dark;
		padding-bottom: 15px;
	}
}
