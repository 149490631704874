// scss-lint:disable ImportantRule
.ui-autocomplete {
	$max-results-height: 400px;

	background-color: $background-color-light;
	box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);
	width: 100%;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	max-height: $max-results-height;
	overflow-y: auto;

	.ui-menu-item {
		padding: 0;
		border: 0;

		&.ui-state-focus {
			border: 0;
			background: $background-color-light;
			font-weight: normal;
			color: $secondary-text-color;
			margin: 0;
		}
	}
}

.mdl-data-table {
	border: 0;
}

.mdl-button {
	font-family: Montserrat, Helvetica, sans-serif;
}

.modals.dimmer .ui.scrolling.modal {
	margin-top: $modal-offset-top !important;

	@include mobile {
		margin: 0 !important;
	}
}

.ui.modal {
	@include mobile {
		top: 0 !important;
		left: 0 !important;
		height: 100%;
		margin: 0 !important;
		border-radius: 0;

		&.small {
			width: 100%;
		}
	}

	.header {
		font-family: Montserrat, Helvetica, sans-serif;
	}

	.header:not(.ui) {
		font-weight: 400;
	}

	.content {
		width: auto;

		@include mobile {
			padding: 50px 20px !important;
		}
	}
}

.ui.dimmer {
	background-color: $dimmer-bacground-color;

	&.modals {
		@include mobile {
			overflow: hidden !important;
		}
	}
}

.mdl-layout__container {
	height: auto;
}

.mdl-textfield {
	width: 100%;
}

.mdl-textfield__input {
	font-family: Montserrat, Helvetica, sans-serif;
}

.mdl-button--icon {
	font-size: 0;
}

.slick-track {
	padding-bottom: 40px;
}
