.table-row-card {
	$content-offset-left: 56px;
	$avatar-size: 32px;

	display: inline-block;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
	border-radius: $border-radius-default;
	text-align: left;

	&.mdl-card {
		overflow: visible;
		z-index: auto;
	}

	.table-row-card__title {
		display: flex;
		align-items: center;
		padding: 10px;
		border-bottom: 1px solid $border-color-default;
		max-height: 72px;

		.table-row-card__title--name {
			width: 100%;
			font-weight: 600;
			font-size: 15px;
			letter-spacing: 0.5px;
			color: $text-color-dark;
			min-height: 20px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 256px;
		}

		.table-row-card__title--time {
			width: 100%;
			font-size: 12px;
			color: $text-color-dark-lighter;
		}

		.table-row-card__title--avatar {
			width: $avatar-size;
			min-width: $avatar-size;
			height: $avatar-size;
			border-radius: 100%;
			margin-right: 12px;
			background: {
				image: url('../images/default-user-avatar.svg');
				repeat: no-repeat;
				size: cover;
				position: center;
			};
		}
	}

	.table-row-card__title-left-side {
		width: $icon-size + 12px;
	}

	.table-row-card__title-center {
		font-size: 16px;
		line-height: 18px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
	}

	.table-row-card__title-right-side {
		width: $icon-size + 12px;
		position: absolute;
		right: 0;
	}

	.table-row-card__content {
		padding: 0 10px;
		width: initial;

		.table-row-card__content--item {
			border-bottom: 1px solid $border-color-default;
			color: $secondary-text-color;
			line-height: 18px;
			padding: 10px 0;
		}
	}

	.table-row-card__actions {
		margin-left: $content-offset-left;
		padding: 18px 0;
	}

	.table-row-card__action-item {
		display: inline-block;
		padding-left: $icon-size + 12px;
		margin-right: 25px;
		background: {
			repeat: no-repeat;
			size: $icon-size;
			position: left center;
		}

		&:last-child {
			margin: 0;
		}

		&.table-row-card__action-item--recommendations {
			background-image: url('../images/recommendation-icon.svg');
		}

		&.table-row-card__action-item--friends {
			background-image: url('../images/friends-full-icon.svg');
		}

		&.table-row-card__action-item--friendswo {
			background-image: url('../images/friends-empty-icon.svg');
		}

		&.table-row-card__action-item--connections {
			background-image: url('../images/friends-full-icon.svg');
		}

		&.table-row-card__action-item--rank {
			background-image: url('../images/rank-icon.svg');
		}

		&.table-row-card__action-item--status {
			font-size: 14px;
			color: $text-color-dark-lighter;
		}
	}
}
