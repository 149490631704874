.summary {
	$summary-padding: 145px;
	$summary-padding-homepage: 15px;
	$summary-padding-claim-business: 85px;
	$summary-padding-mobile: 75px;

	$search-font-size: 62px;
	$search-font-size-mobile: 50px;
	$search-line-height: 76px;
	$search-line-height-mobile: 60px;

	background: $primary-background-color;

	&.summary--no-padding {
		padding-top: 0;
	}

	.summary__info-container {
		padding-top: $summary-padding;
		padding-bottom: $summary-padding;

		@include mobile {
			padding-top: $summary-padding-mobile;
			padding-bottom: $summary-padding-mobile;
		}

		&.summary__info-contaner--less-padding {
			padding-top: $summary-padding / 4;
			padding-bottom: $summary-padding / 4;

			@include mobile {
				padding-top: $summary-padding-mobile / 4;
				padding-bottom: $summary-padding-mobile / 4;
			}
		}

		&.summary__info-container--without-bottom-padding {
			padding-bottom: 0;
		}
		&.summary__info-container--homepage {
			padding-top: $summary-padding-homepage;
			position: relative;

			.summary__main-container {
				.summary__background {
					img {
						width: 100%;
						min-width: 1200px;
						min-height: 300px;
					}
				}
				.summary__container-text {
					position: absolute;
					width: 570px;
					padding: $summary-padding-homepage;
					background-color:rgba(0, 0, 0, 0.7);
					left: 100px;
					top: 30px;
					@include mobile {
						left: 0;
						width: 90%;
						padding: 5%;
					}

					.summary__title {
						font-size: 36px;
						font-weight: 600;
						text-align: left;
						padding: 0;
						@include mobile {
							font-size: 24px;
						}
					}

					.summary__description {
						color: $primary-text-color;
						font-size: 20px;
						@include mobile {
							font-size: 16px;
						}
					}
				}
			}
		}

		&.summary__info-container--requests {
			padding-top: 50px;
			padding-bottom: 20px;
		}

		&.summary__info-container--one-line-title {
			padding: 50px 0;
		}

		.summary__invite-container {
			text-align: center;
			padding: 0;
			border-bottom: 0 none;

			.invite-container__invite-btn {
				margin: 5px 0 0;
				font-size: 17px;
				
				.small-text {
					font-size: 13px;
				}
			}
		}
	}

	.summary__separator {
		height: 190px;

		&.summary__separator--small {
			height: 60px;
		}

		&.summary__separator--large {
			height: 170px;
		}

		@include mobile {
			height: 100px;
		};
	}

	.summary__wrapper {
		min-height: 300px;

		&.summary__wrapper--table {
			text-align: center;

			&.summary__wrapper--table-prefilters {
				@include tablet {
					// scss-lint:disable NestingDepth
					.summary__separator {
						height: 185px;

						&.summary__separator--small {
							height: 110px;
						}

						&.summary__separator--large {
							height: 230px;
						}
					}
				};
			}
		}
	}

	.summary__title {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 130px;
		color: $primary-text-color;
		font-size: $search-font-size;
		font-weight: 200;

		&.summary__title-smaller {
			font-size: $search-font-size / 1.75;
		}

		img {
			max-height: 1.2em;
			padding: 0 25px 15px;
		}

		@include mobile {
			font-size: 42px;
			padding: 0 30px;
			flex-direction: column;

			&.summary__title-smaller {
				font-size: 24px;
			}
		}
	}

	.summary__heading {
		font-size: 30px;
		color: #fff;
		text-align: center;

		&.summary__heading--spaced {
			margin-top: 35px;
		}
	}

	.summary__subtitle {
		font-size: 15px;
		color: $secondary-text-color-light;
		text-align: center;
		line-height: 27px;

		@include mobile {
			margin-top: 64px;
		}

		&.summary__subtitle--spaced {
			margin-top: 35px;
		}

		&.summary__subtitle--spaced-bottom {
			margin-bottom: 50px;
		}
	}

	.summary__publish-container {
		text-align: center;
		color: $primary-text-color;
		padding: 30px 0;
	}

	.summary__avatar-container {
		margin-right: 20px;

		@include mobile {
			margin-right: 0;
			margin-bottom: 25px;
		}
	}

	.summary__progress {
		padding-top: 55px;
		text-align: center;

		@include mobile {
			padding-top: 26px;
		}

		&.summary__progress--stage-one {
			.svg-progress--stage-one {
				opacity: 1;
			}
		}

		&.summary__progress--stage-two {
			.svg-progress--stage-one {
				opacity: 1;
			}

			.svg-progress--stage-two {
				opacity: 1;
			}
		}
	}

	.summary__search-places-container {
		margin: 20px auto 0 auto;
	}

	.summary__separator--requests,
	.summary__separator--offers,
	.summary__separator--recommendations {
		height: 170px;
		text-align: center;
	}

	.summary__search-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 662px;
		margin: 0 auto;
		padding: 120px 15px 200px 15px;

		@include mobile {
			padding: 95px 15px 150px 15px;
		}

		&.summary__search-container--full {
			cursor: pointer;

			.summary__search-container--search-icon {
				display: none;
			}

			.summary__search-container--clear-icon {
				display: block;
			}
		}

		.summary__search-container--clear-icon {
			display: none;
		}
	}

	.summary__input-container {
		margin: 0 30px;
	}

	.summary__search-input {
		color: $primary-text-color;
		border-bottom: 1px solid $border-color-branding-dark;
		font-weight: 200;
		font-size: $search-font-size;
		line-height: $search-line-height;
	}

	.summary__search-label {
		font-weight: 200;
		color: $text-color-branding-light;
		font-size: $search-font-size;
		line-height: $search-line-height;

		&:after {
			background-color: $background-color-light;
		}
	}

	&.summary--claim-business {
		.summary__info-container {
			padding-top: $summary-padding-claim-business;
			padding-bottom: $summary-padding-claim-business;
		}

		.summary--claim-business__title {
			color: #fff;
			font-size: 22px;
			margin-top: 35px;
		}
	}

	@include mobile {
		.summary__search-input {
			font-size: $search-font-size-mobile;
			line-height: $search-line-height-mobile;
		}

		.summary__search-label {
			font-size: $search-font-size-mobile;
			line-height: $search-line-height-mobile;
		}
	};
}

.publish-container {
	font-size: 15px;

	.publish-container__label {
		color: $secondary-text-color-light;
		padding: 0 16px;
		vertical-align: middle;
		line-height: $icon-size;

		.publish-container__label--pending-verification {
			display: inline-block;
			padding-left: $icon-size + 8px;
			background: {
				image: url('../images/lock-light-icon.svg');
				repeat: no-repeat;
				size: $icon-size;
				position: left center;
			};
		}

		.publish-container__label--published {
			color: $text-color-published;
		}
	}

	.publish-container__button {
		color: $primary-text-color;
		text-transform: uppercase;
		cursor: pointer;
		padding-right: $icon-size + 5px;
		background: {
			image: url('../images/arrow-down.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: right center;
		};
	}
}

.avatar {
	display: inline-block;
	position: relative;
	height: $avatar-size;
	min-width: $avatar-size;
	max-width: $avatar-size;
	border-radius: $border-radius-default;
	cursor: pointer;
	font-size: 0;
	text-align: center;

	.avatar__overlay {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: $avatar-size;
		height: $avatar-size;
		border-radius: $border-radius-default;
		opacity: 0;
		transition: visibility 0s, opacity 0.3s ease;
		background: {
			color: $background-color-avatar;
			image: url('../images/delete-icon.svg');
			repeat: no-repeat;
			size: $icon-size;
			position: center;
		};
	}

	&.avatar--full {
		border: 2px solid #fff;
		background: {
			image: url('../images/camera-full.svg');
			repeat: no-repeat;
			size: 100%;
			position: center;
		};

		&:hover {
			.avatar__overlay {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&.avatar--empty {
		background: {
			size: $icon-size;
		};

		&.is-loading {
			background: {
				image: url('../images/spinner.svg') !important;
				size: 50px;
			}
		}
	}

	.avatar__form {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
	}

	.avatar__browse-input {
		height: $avatar-size;
		width: $avatar-size;
		cursor: pointer;
	}

	.mdl-spinner {
		top: 50%;
		margin-top: -14px;
	}
}


// negative margins
$margin-bottom-business-info: -72px;
$margin-bottom-clients: -113px;
$margin-bottom-clients-mobile: -149px;
$margin-bottom-recommendations: -169px;
$margin-bottom-recommendations-mobile: -137px;
$margin-bottom-offers: -169px;
$margin-bottom-offers-mobile: -137px;
$margin-bottom-request: -169px;
$margin-bottom-request-mobile: -173px;
$margin-bottom-admin-businesses: -169px;
$margin-bottom-admin-businesses-mobile: -211px;
$margin-bottom-reports: -169px;
$margin-bottom-reports-mobile: -173px;

// scss-lint:disable MergeableSelector
.summary {
	&.summary--business-info {
		margin-bottom: $margin-bottom-business-info;
	}

	&.summary--clients {
		margin-bottom: $margin-bottom-clients;

		@include tablet {
			margin-bottom: $margin-bottom-clients-mobile;
		}
	}

	&.summary--recommendations {
		margin-bottom: $margin-bottom-recommendations;

		@include tablet {
			margin-bottom: $margin-bottom-recommendations-mobile;
		}
	}

	&.summary--offers {
		margin-bottom: $margin-bottom-offers;

		@include tablet {
			margin-bottom: $margin-bottom-offers-mobile;
		}
	}

	&.summary--requests {
		margin-bottom: $margin-bottom-request;

		@include tablet {
			margin-bottom: $margin-bottom-request-mobile;
		}
	}

	&.summary--admin-businesses {
		margin-bottom: $margin-bottom-admin-businesses;

		@include tablet {
			margin-bottom: $margin-bottom-admin-businesses-mobile;
		}
	}

	&.summary--reports {
		margin-bottom: $margin-bottom-reports;

		@include tablet {
			margin-bottom: $margin-bottom-reports-mobile;
		}
	}
}
