.summary {
	$summary-stat-icon-size: 48px;
	$max-stats-width: 780px;
	$invite-container-size: 58px;
	$invite-container-size-mobile: 40px;

	.summary__invite-container {
		@include clearfix();
		max-width: $max-table-width;
		margin: 0 auto;
		padding: 16px 0;
		border-bottom: 1px solid $text-color-branding-light;

		@media (max-width: 1210px) {
			margin: 0 20px;
		}

		.summary__invite-container--left {
			display: flex;
			align-items: center;
			float: left;

			@include mobile {
				float: none;
			}
		}

		.summary__invite-container--right {
			display: flex;
			align-items: center;
			float: right;
			height: $invite-container-size;

			@include mobile {
				float: none;
				clear: both;
				display: block;
				margin: 10px auto;

				.invite-container__description {
					clear: both;
					float: none;
					display: block;
					margin: 20px 0 10px;
				}
			}
		}
	}

	.invite-container {
		.invite-container__title {
			display: inline-block;
			margin-left: 16px;
			font-size: 16px;
			color: $primary-text-color;
		}

		.invite-container__description {
			color: $secondary-text-color-light;
		}

		.invite-container__invite-btn {
			padding: 10px 20px;
			margin-left: 24px;
		}

		.invite-container__avatar {
			display: inline-block;
			height: $invite-container-size;
			width: $invite-container-size;
			border-radius: $border-radius-small;
			border: 2px solid #FFFFFF;
			background: {
				repeat: no-repeat;
				size: 100%;
				position: center;
			};

			@include mobile {
				height: $invite-container-size-mobile;
				width: $invite-container-size-mobile;
			};

			&.invite-container__avatar--empty {
				background: {
					color: $primary-background-color-light;
					image: url('../images/camera-full.svg');
					size: $icon-size;
				}
			}
		}
	}

	.summary__stats {
		display: flex;
		max-width: $max-stats-width;
		margin: 0 auto;
		padding: 50px 20px;

		@include mobile {
			display: inline-block;
			padding: 20px;
		};
	}

	.summary__stat {
		display: flex;
		flex: 1;
		color: $primary-text-color;

		@include mobile {
			display: block;
			margin: 20px 0;
		};

		&.summary__stat--left {
			justify-content: flex-start;
		}

		&.summary__stat--center {
			justify-content: center;
		}

		&.summary__stat--right {
			justify-content: flex-end;
		}
	}

	.summary__stat-icon {
		display: inline-block;
		height: $summary-stat-icon-size;
		width: $summary-stat-icon-size;
		margin-right: 15px;
	}

	.summary__stat-value {
		display: inline-block;
		font-weight: 200;
		font-size: 60px;
		vertical-align: middle;
	}

	.summary__stat-label {
		font-size: 14px;
		color: $secondary-text-color-light;
	}

	.summary__stats--simple {
		.summary__stat-value {
			font-size: 50px;
		}

		.summary__stat-icon {
			display: none;
		}
	}
}

.increase-reach-section {
	background-color: $primary-background-color-light;
	padding: 100px 50px;
	text-align: center;

	@include mobile {
		padding: 50px 20px;
	}

	.increase-reach-section__title {
		font-weight: 200;
		font-size: 52px;
		color: $primary-text-color;
		text-align: center;
		margin-bottom: 60px;

		@include mobile {
			font-weight: 300;
			font-size: 20px;
			line-height: 32px;
			margin: 0 auto;
			width: 180px;
		}
	}

	.increase-reach-section__invite-btn {
		width: 560px;

		@include mobile {
			margin: 0;
			margin-top: 30px;
			width: 100%;
		}
	}

	.increase-reach-section__instructions-container {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.increase-reach-section__instruction {
		display: table-cell;
		margin: 0 28px;

		.increase-reach-section__instruction--image-wrapper {
			display: table-cell;
			vertical-align: middle;
			height: 150px;
		}

		.increase-reach-section__instruction--label {
			font-weight: 600;
			font-size: 16px;
			color: $primary-text-color;
			line-height: 30px;
			margin: 40px auto 0 auto;
			max-width: 165px;
		}

		.increase-reach-section__instruction--search-rank {
			display: inline-block;

			&:first-child {
				margin-right: -30px;
			}
		}
	}
}
